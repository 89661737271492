<template>
  <div class="container">
    <!-- header -->
    <div class="header">
      <div>
        <div class="row">
          <div class="col text-center">
            <div class="title-1">Upload file</div>
            <div class="description">
              Selected files have been uploaded to the scanned directory.
            </div>
          </div>
          <ThemeSwitcher class="q-ml-sm theme-switch" :no-border="false" />
        </div>

        <!-- content  -->
        <div class="content">
          <BaseScrollbar height="calc(-211px + 100vh)" class="q-pa-xs">
            <div class="upload-section">
              <div v-if="!fileUploaded" class="upload-content">
                <h6>{{ fieldName }}</h6>
                <p>
                  This page will be expire in
                  <span style="color: red">{{ remainTime }}</span>
                </p>
                <!-- <p>Some description</p> -->
                <div class="file-upload">
                  <input
                    id="file-input"
                    type="file"
                    hidden
                    @change="handleFileUpload"
                  />
                  <label for="file-input" class="file-label">
                    <div class="file-icon">
                      <BaseIcon
                        name="eva-cloud-upload-outline"
                        size="20px"
                        color="primary"
                        class="icon"
                      />
                    </div>
                    <p>Select file</p>
                  </label>
                </div>
                <!-- <p>or</p> -->
                <div>
                  <div class="or-section"><span>(or)</span></div>
                </div>

                <div class="file-upload">
                  <input
                    id="camera-input"
                    type="file"
                    accept="image/*"
                    capture="environment"
                    hidden
                    @change="handleFileUpload"
                  />

                  <label for="camera-input" class="file-label">
                    <div class="file-icon">
                      <BaseIcon
                        name="mdi-camera"
                        size="20px"
                        color="primary"
                        class="icon"
                      />
                    </div>
                    <p>Capture image</p>
                  </label>
                </div>

                <div class="file-preview">
                  <img v-if="isImage" :src="imageSrc" class="preview-image" />
                  <p v-else-if="fileName" class="fileName">
                    <FileIcon
                      :mime-type="fileType(fileName)"
                      class="mini-avatar q-mr-sm"
                    />
                    {{ fileName }}
                  </p>
                </div>
                <div v-if="isUploading" class="loading-bar">
                  <q-circular-progress
                    show-value
                    font-size="12px"
                    :value="uploadProgress"
                    size="lg"
                    :thickness="0.22"
                    color="primary"
                    track-color="grey-3"
                    class="q-mr-sm"
                  >
                    {{ uploadProgress }}%
                  </q-circular-progress>
                </div>
              </div>
              <template v-if="fileUploaded">
                <StateWrapper
                  v-if="timeOut"
                  icon="mdi-file-clock-outline"
                  title="URL expired"
                  description="Generate a new QR to upload documents"
                  style="margin-top: 80vh !important"
                />
                <StateWrapper
                  v-else
                  icon="mdi-file-check-outline"
                  title="Documents uploaded"
                  description="Generate a new QR to upload documents"
                  style="margin-top: 80vh !important"
                />
              </template>
            </div>
          </BaseScrollbar>
          <button
            v-if="!fileUploaded"
            class="continue-button"
            @click="uploadFile"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
    <ImageCropper
      v-model="isImageCropperVisible"
      :image="originalImage"
      @save="saveImage"
    />
  </div>
</template>

<script>
import ThemeSwitcher from "@/layouts/app/components/toolbar/components/ThemeSwitcher.vue";
import { auth, repository } from "@/api/factory.js";
import axios from "axios";
import { fileSupport } from "@/helpers/file-format.js";
import ImageCropper from "@/components/common/form/image-field/ImageCropper.vue";
import StateWrapper from "@/components/common/state/StateWrapper.vue";
import FileIcon from "@/components/common/FileIcon.vue";

export default {
  name: "MobileUpload",

  components: {
    ThemeSwitcher,
    ImageCropper,
    StateWrapper,
    FileIcon,
  },
  props: {
    tenantId: {
      type: String,
      required: true,
    },

    userId: {
      type: String,
      required: true,
    },

    uniqueId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      file: null,
      filePreview: null,
      isImage: false,
      fileName: "",
      isUploading: false,
      uploadProgress: 0,
      attachmentList: [],
      imageSrc: "",
      isImageCropperVisible: false,
      repositoryId: 0,
      url: "",
      upload: false,
      fieldId: "",
      originalImage: "",
      fieldName: "",
      fileUploaded: false,
      timeOut: false,
      remainingTime: 180,
      remainTime: "03:00",
    };
  },

  watch: {
    userId: {
      immediate: true,
      handler: "getSession",
    },
  },

  methods: {
    saveImage(croppedImage) {
      this.isImageCropperVisible = false;
      console.log(croppedImage, "croppedImage");
      this.imageSrc = croppedImage;
      const newFile = this.base64ToFile(croppedImage, this.file.name);
      this.file = newFile;
      // this.filePreview = croppedImage;
      // this.$emit("input", croppedImage);
    },

    base64ToFile(base64String, fileName) {
      const arr = base64String.split(",");
      const mimeType = arr[0].match(/:(.*?);/)[1];
      const byteString = atob(arr[1]);

      const n = byteString.length;
      const uint8Array = new Uint8Array(n);

      for (let i = 0; i < n; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
      }

      return new File([uint8Array], fileName, { type: mimeType });
    },

    async getSession() {
      console.log(this.tenantId, this.userId, this.uniqueId);
      const { error } = await auth.externalLogin(this.tenantId, this.userId);
      if (error) {
        this.$alert.info("User Account Not Found");
        return;
      }
      this.getProcess();
    },

    async getProcess() {
      const { error, payload } = await repository.getQrFileUploadById(
        this.uniqueId
      );
      console.log(error, payload, "error, payload");

      if (error) {
        this.upload = true;
        this.$alert.info("form details Not Found");
        return;
      }
      if (payload.status) {
        this.upload = true;
        this.$alert.info("file already uploaded");
        this.fileUploaded = true;
        return;
      }
      let jsonData = JSON.parse(payload.jsonData);
      console.log(jsonData, "jsonData");
      this.repositoryId = jsonData[0].repositoryId;
      this.fieldId = jsonData[0].fieldId;
      this.fieldName = jsonData[0].fieldName;
      this.url = jsonData[0].url;
      this.timeOut = jsonData[0].expired;
      this.time = jsonData[0].time;
      if (this.timeOut) {
        this.fileUploaded = true;
        this.$alert.info("QR Expired");
        return;
      } else {
        this.getRemainTime();
      }
    },
    getRemainTime() {
      this.remainingTime = 180;

      const countdown = setInterval(() => {
        let currentTime = new Date().getTime();
        let timeDifference = (currentTime - this.time) / 1000;

        if (timeDifference >= 180) {
          clearInterval(countdown);
          this.remainTime = "00:00";
          this.fileUploaded = true;
          this.timeOut = true;
          this.$alert.info("QR Expired");
          return;
        }

        this.remainingTime = 180 - Math.floor(timeDifference);

        let minutes = Math.floor(this.remainingTime / 60);
        let seconds = this.remainingTime % 60;

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        this.remainTime = `${minutes}:${seconds}`;
      }, 1000);
    },
    fileType(fileName) {
      if (fileName) return fileName.split(".").reverse()[0].slice(0, 4);
      else "";
    },
    async handleFileUpload(event) {
      this.originalImage = "";
      const file = event.target.files[0];

      if (!file) return;

      const reader = new FileReader();

      const fileData = await new Promise((resolve, reject) => {
        reader.onload = (e) => {
          resolve(e.target.result);
        };
        reader.onerror = reject;

        reader.readAsDataURL(file);
      });

      this.filePreview = fileData;
      console.log(file.name, "file.name");

      this.file = file;
      this.fileName = file.name;
      console.log(file.type.startsWith("image/"), "file.type.startsWith(img)");
      this.isImage = file.type.startsWith("image/");

      console.log(file, "file");

      if (this.isImage) {
        this.originalImage = this.filePreview;
        this.imageSrc = this.filePreview;
        this.isImageCropperVisible = true;
      } else {
        this.filePreview = true;
        this.imageSrc = "";
      }

      console.log(this.filePreview, "this.filePreview");
      this.getProcess();
    },

    async uploadFile() {
      this.getProcess();
      if (!this.file) return;
      if (this.upload) {
        this.$alert.info("file already uploaded");
        this.fileUploaded = true;
        return;
      }
      this.isUploading = true;
      this.uploadProgress = 0;
      // Simulate file upload
      const interval = setInterval(() => {
        if (this.uploadProgress < 90) {
          this.uploadProgress += 10;
        } else {
          clearInterval(interval);
        }
      }, 500);
      const file = this.file;
      let fields = this.file.name;
      console.log(file);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("repositoryId", this.repositoryId);
      if (fields) {
        // this.fileData = JSON.parse(fields);
        formData.append("filename", this.file.name);
      }
      let filename = this.file.name;
      const ext = filename.split(".").pop();

      if (!fileSupport(ext)) {
        this.$alert.info("This file format is not supported");
        return;
      }
      console.log(formData, "formData");
      this.$store.commit("showLoadingBar");
      try {
        const response = await axios({
          url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/upload`,
          method: "POST",
          data: formData,
          headers: {
            Token: this.$store.state.identity.token,
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        });
        this.$store.commit("hideLoadingBar");
        console.log(response, "response");
        const { status, data } = response;
        // e.target.value = "";
        if (status !== 200) {
          throw "Invalid response";
        }
        let filesList = JSON.parse(data);
        this.attachmentList.push({
          id: Number(filesList.fileId),
          name: filename,
          createdAt: new Date().toISOString(),
          createdByEmail: this.$store.state.session.email,
          size: file.size,
          initiate: true,
          uid: this.rowIndex,
        });

        console.log(this.attachmentList, "this.attachmentList");
        await this.updateJson();
      } catch (error) {
        console.error(error);
        this.$alert.error(`error attaching file`);
      }
    },
    async updateJson() {
      console.log(this.repositoryId, this.url, this.attachmentList);
      let input = {
        id: this.uniqueId,
        url: this.url,
        jsonData: JSON.stringify([
          {
            repositoryId: this.repositoryId,
            fieldId: this.fieldId,
            attachmentList: this.attachmentList,
          },
        ]),
        status: 1,
      };
      const { error, payload } = await repository.updateJson(
        this.uniqueId,
        input
      );
      this.uploadProgress = 100;
      console.log(error, payload, "error, payload");
      this.$alert.success(`file uploaded successfully!`);
      this.isUploading = false;
      location.reload();
      // this.$alert.success(`Document attached`);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding: 16px;
  border-radius: 4px;
  width: 100%;
  background-color: var(--component-bg-color);
  border-top: 4px solid var(--primary);
  display: flex;
  height: 100%;
  justify-content: center;

  .description {
    color: var(--icon-color-inverted);
  }
  .theme-switch {
    position: absolute;
    right: 40px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: auto;
  height: 100vh;
  background: linear-gradient(135deg, #dec2e7, #b2ebf2);
  overflow-y: hidden;
}
.upload-content {
  width: 100%;
}
.content {
  max-width: 400px;
  width: 100%;
  height: 85%;
}
h6 {
  margin: 0px;
}

.upload-section {
  text-align: center;
  width: 100%;
  overflow-y: auto;
  height: 77%;
  display: flex;
  align-items: center;
  margin: 30px 0px;
  justify-content: center;
}

.file-upload {
  border: 1px solid var(--primary);
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  margin-bottom: 10px;
  cursor: pointer;
}

.file-label {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-icon {
  font-size: 2em;
}

.camera-button {
  // background-color: #aa86c06e;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.continue-button {
  background-color: var(--secondary);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 15px 30px;
  font-size: 1em;
  cursor: pointer;
  width: 100%;
}

.file-preview {
  text-align: center;
  margin: 20px 0;
}

.preview-image {
  max-width: 100%;
  max-height: 200px;
  border-radius: 8px;
  margin: 10px 0;
}

.loading-bar {
  margin-top: 20px;
}
.or-section {
  display: flex;
  justify-content: center;
  color: #cdd5dc;
  margin: 15px 0 15px 0;
}
.or-section:after,
.or-section:before {
  content: "........................";
  flex: 1;
  height: 1px;
  margin: -3px 10px;
}
.mini-avatar {
  width: 18px !important;
  height: 18px !important;
}
.fileName {
  align-items: center;
  display: flex;
  justify-content: center;
}
@media (min-width: 768px) {
  .header {
    width: 50%;
    .theme-switch {
      position: absolute;
      right: 26.5%;
    }
  }
}
</style>
